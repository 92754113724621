import React, { useState } from 'react';

import '../globals.css'

interface DropdownOption {
  value: string;
  label: string;
}

const optionsFromStringList = (stringOptions: string[]) => stringOptions.map((option, index) => ({
  value: option,  //  `option${index + 1}`,
  label: option,
}));

interface DropdownFormProps {
  options: DropdownOption[];
  onOptionSelect: (selected: string) => void;
}

const DropdownForm: React.FC<DropdownFormProps> = ({ options , onOptionSelect }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    onOptionSelect(value);
  };

  return (
        <select value={selectedOption} onChange={handleChange}>
          <option value="">Organization ID</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
  );
};

export default DropdownForm;
export { optionsFromStringList };
export type { DropdownOption };
