import React, { FormEvent, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import '../globals.css'
import { dfnsApi } from '../api'
import { UserStatuses } from '../types/apptypes'
import  DropdownForm, { optionsFromStringList, DropdownOption } from '../components/dropdown'

import useAuth from '../hooks/useAuth'
import { DfnsError } from '@dfns/sdk'

export default function Login(): JSX.Element {
  const { login, loading, error } = useAuth()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [errorBe, setError] = useState<DfnsError | undefined>(undefined)
  const [isDropdown, setIsDropdown] = useState<boolean>(false)
  const [orgIds, setOrgIds] = useState<DropdownOption[]>()
  const [orgId, setOrgId] = useState<string>()
  const [username, setUsername] = useState<string>("")

  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)

    login(formData.get('username') as string, orgId as string)
  }

  const handleOrgList = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setSubmitting(true)
    setError(undefined)

    dfnsApi()
      .auth.getOrgList({ body: { email: username as string, status: UserStatuses.Registered} })
      .then((orgIdsResp) => {
        if(orgIdsResp.items.length == 0) {
          const err = new DfnsError(200, "Registration not found")
          setError(err)
        } else if (orgIdsResp.items.length > 1) {
          setIsDropdown(true)
          const orgIds = orgIdsResp.items.map((r) => r.orgId)
          setOrgIds(optionsFromStringList(orgIds))
        } else {
          login(username as string, orgIdsResp.items[0].orgId)
        }
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOptionSelect = (selected: string) => {
    setOrgId(selected)
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };


  return (
    <form onSubmit={orgId ? handleLogin : handleOrgList}>
      <div className="w-full p-10">
        <h1 className="text-2x">Login</h1>

        <div className="flex items-center gap-2">
          <input 
            className="input" 
            id="username" 
            name="username" 
            placeholder="email" 
            value={username} 
            onChange={handleUsernameChange}
            />

          {isDropdown ? 
          <DropdownForm options={orgIds ?? []} onOptionSelect={handleOptionSelect} />
          : <div></div>
          }

          <button className="btn" disabled={loading} type="submit">
            Login
          </button>
        </div>

        {!!error && <div className="text-red-700">{error.message}</div>}
        {!!errorBe && <div className="text-red-700">{errorBe.message}</div>}
        
        <p>
          <a href={`/register?username=${username ?? ""}&orgId=${orgId ?? ""}`}>Registration</a>
        </p>

        {/* <p>
          You can login if your Dfns user already has a WebauthN Credential registered on this Application (this
          domain). If you don't, you first need to <a href="/credential">create a new Credential</a> for this
          Application.
        </p> */}

      </div>
    </form>
  )
}
