import { ListUsersResponse, User } from '@dfns/sdk/types/auth'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import '../globals.css'
import { dfnsApi } from '../api'
import useAuth from '../hooks/useAuth'
import { DfnsError } from '@dfns/sdk/dfnsError'
import { useNavigate } from 'react-router-dom'
import { ListWebhooksResponseItem } from '@dfns/sdk/generated/webhooks'

export default function AdminAction(): JSX.Element {
  const { user, logout, orgId } = useAuth()
  const [submittingPermId, setSubmittingPermId] = useState<boolean>(true)
  const [submittingPerm, setSubmittingPerm] = useState<boolean>(false)
  const [submittingServiceAcc, setSubmittingServiceAcc] = useState<boolean>(false)
  const [submittingServiceAccID, setSubmittingServiceAccID] = useState<boolean>(true)
  const [submittingWalletOwner, setSubmittingWalletOwner] = useState<boolean>(false)
  const [error, setError] = useState<DfnsError | undefined>(undefined)
  const navigate = useNavigate()
  // const [users, setUsers] = useState<User[] | undefined>(undefined)

  const [permIdServiceAcc, setPermIdServiceAcc] = useState<string | undefined>(undefined)
  const [permNameServiceAcc, setPermNameServiceAcc] = useState<string >("")
  const [permIdAppover, setPermIdApprover] = useState<string | undefined>(undefined)
  const [permNameApprover, setPermNameApprover] = useState<string >("")
  const [permIdWalletOwner, setPermIdWalletOwner] = useState<string | undefined>(undefined)
  const [permNameWalletOwner, setPermNameWalletOwner] = useState<string >("")

  const [serviceAccId, setServiceAccId] = useState<string | undefined>(undefined)
  const [publicKey, setPublicKey] = useState<string | undefined>(undefined)
  const [serviceAccName, setServiceAccName] = useState<string>("")

  const [assignId, setAssignId] = useState<string | undefined>(undefined)
  const [respMessage, setRespMessage] = useState<string | undefined>(undefined)
  const [username, setUsername] = useState<string>("")

  const [webhookId, setWebhookId] = useState<string | undefined>(undefined)
  const [submittingWebhookId, setSubmittingWebhookId] = useState<boolean>(false)

  const [webhooks, setWebhooks] = useState<ListWebhooksResponseItem[]>([])
  const [submittingDeleteWh, setSubmittingDeleteWh] = useState<boolean>(false)

  const [users, setUsers] = useState<ListUsersResponse | undefined>(undefined)


  useEffect(() => {
    dfnsApi()
      .permissions.getPermissionIds({ body: { orgId:  orgId ?? ""} })
      .then((resp) => {
        console.log("PermId: ", resp)
        setPermIdServiceAcc(resp.serviceAccPermId)
        setPermNameServiceAcc(resp.serviceAccPermName)
        setPermIdApprover(resp.approverPermId)
        setPermNameApprover(resp.approverPermName)
        setPermIdWalletOwner(resp.walletOwnerPermId)
        setPermNameWalletOwner(resp.walletOwnerPermName)
      })
      .catch((err: DfnsError | undefined) => {
        setError(err)
        if (err?.message == "Authorization error" || err?.message == "user not found or disabled") {
          navigate("/login")
        }
      })
      .finally(() => {
        setSubmittingPermId(false)
      })
  }, [])

  useEffect(() => {
    dfnsApi()
      .permissions.getServiceAccId({ body: { orgId:  orgId ?? ""} })
      .then((resp) => {
        console.log("ServiceAccId: ", resp.serviceAccId)
        if (!resp.serviceAccId || resp.serviceAccId == "") {
          setPublicKey(resp.publicKey)
        } else {
          setServiceAccId(resp.serviceAccId)
          getWebhookId()
          getWebhookList()
          getUsers()
        }
        setServiceAccName(resp.name)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingServiceAccID(false)
      })
  }, [])

  const getWebhookList = () => {
    setError(undefined)
    dfnsApi()
      .webhooks.listWebhooks({  })
      .then((resp) => {
        setWebhooks(resp.items)
      })
      .catch((err) => {
        setError(err)
      })
  }

  const getUsers = () => {
    setError(undefined)
    dfnsApi()
      .auth.listUsers({  })
      .then((resp) => {
        setUsers(resp)
      })
      .catch((err) => {
        setError(err)
      })
  }

  const getWebhookId = () => {
    setError(undefined)
    dfnsApi()
      .webhooks.getWebhookId()
      .then((resp) => {
        setWebhookId(resp.webhookId)
      })
      .catch((err) => {
        setError(err)
      })
  }


  const handleCreateServiceAccPermissions = () => {
    setSubmittingPerm(true)
    setError(undefined)

    dfnsApi()
      .permissions.createPermission({ body: {
        name: permNameServiceAcc,
        operations: [
          "Auth:Users:Create",
          "Auth:Users:Read",
          "Auth:Users:Update",
          "Auth:Types:Employee",
          "Policies:Archive",
          "Policies:Create",
          "Policies:Read",
          "Policies:Update",
          "Policies:Approvals:Read",
          "PermissionAssignments:Create",
          "Wallets:Tags:Add",
          "Wallets:Create",
          "Webhooks:Create",
          "Webhooks:Read",
          "Webhooks:Delete"
          ]
      }})
      .then((resp) => {
        if(resp) {
          console.log(resp.id)
          setPermIdServiceAcc(resp.id)
        }
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingPerm(false)
      })
  }

  const handleCreateApproverPermissions = () => {
    setSubmittingPerm(true)
    setError(undefined)

    dfnsApi()
      .permissions.createPermission({ body: {
        name: permNameApprover,
        operations: [
          "Policies:Approvals:Approve",
          "Policies:Approvals:Read",
          ]
      }})
      .then((resp) => {
        if(resp) {
          console.log("Approval permission id:", resp.id)
          setPermIdApprover(resp.id)
        }
      })
      .catch((err) => {
        console.log(err)
        setError(err)
      })
      .finally(() => {
        setSubmittingPerm(false)
      })
  }

  const handleCreateWalletOwnerrPermissions = () => {
    setSubmittingPerm(true)
    setError(undefined)

    dfnsApi()
      .permissions.createPermission({ body: {
        name: permNameWalletOwner,
        operations: [
          "Wallets:Read",
          "Wallets:ReadSignature",
          "Wallets:ReadTransaction",
          "Wallets:ReadTransfer",
          "Wallets:GenerateSignature",
          "Wallets:BroadcastTransaction",
          "Wallets:TransferAsset",
          "Wallets:Create",
          "Wallets:Tags:Add",
          ]
      }})
      .then((resp) => {
        if(resp) {
          console.log("WalletOwner permission id:", resp.id)
          setPermIdWalletOwner(resp.id)
        }
      })
      .catch((err) => {
        console.log(err)
        setError(err)
      })
      .finally(() => {
        setSubmittingPerm(false)
      })
  }

  const handleCreateServiceAcc = () => {
    setSubmittingServiceAcc(true)
    setError(undefined)

    dfnsApi()
      .auth.createServiceAccount({ body: {
        name: serviceAccName,
        publicKey: publicKey ?? "",
        permissionId: undefined, //permIdServiceAcc,
        externalId: "",
        daysValid: 365*100,
    }})
      .then((resp) => {
        if(resp) {
          setServiceAccId(resp.userInfo.userId)
          getWebhookId()
          getWebhookList()
          getUsers()
        }
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingServiceAcc(false)
      })
  }

  const handleAssignPermitionsServiceAcc = () => {
    setSubmittingServiceAcc(true)
    setError(undefined)

    dfnsApi()
      .permissions.createAssignment({ 
        permissionId: permIdServiceAcc ?? "",
        body: {
          identityId: serviceAccId ?? "",
        }})
      .then((resp) => {
        console.log("Assigned to: ", resp.identityId)
        setAssignId(resp.id)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingServiceAcc(false)
      })
  }

  const handleCreateUser = () => {
    setError(undefined)

    if (!username) {
      setError(new DfnsError(400, "User not defined"))
      return
    }

    setSubmittingWalletOwner(true)
    dfnsApi()
      .auth.createUser({ body: { 
        email: username ?? "",
        kind: "CustomerEmployee",
      } })
      .then((resp) => {
        console.log("UserId: ", resp.userId)
        setRespMessage("Sent email to "+ resp.username)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingWalletOwner(false)
      })
  }

  const handleArchiveUser = (userId: string) => {
    setError(undefined)
    setSubmittingWalletOwner(true)
    dfnsApi()
      .auth.archiveUser({
        userId: userId
      })
      .then((resp) => {
        console.log("Archived UserId: ", resp.userId)
        const items = users?.items.filter((v) => {
          if (v.userId == userId) {
            return false
          }
          return true
        }) ?? []
        const newUsers = {
          items: items,
          nextPageToken: users?.nextPageToken
        }
        setUsers(newUsers)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingWalletOwner(false)
      })
  }

  const handleCreateWebhook = () => {
    setError(undefined)
    setSubmittingWebhookId(true)
    dfnsApi()
      .webhooks.createWebhookNew()
      .then((resp) => {
        setWebhookId(resp.id)

        dfnsApi()
        .webhooks.listWebhooks({  })
        .then((resp) => {
          setWebhooks(resp.items)
        })
        .catch((err) => {
          setError(err)
        })
        
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingWebhookId(false)
      })
  }

  const handleDeleteWebhook = (webhookId: string) => {
    setError(undefined)
    setSubmittingDeleteWh(true)
    dfnsApi()
      .webhooks.deleteWebhook({webhookId: webhookId})
      .then((resp) => {
        const newWebhooks = webhooks.filter((v) => {
          if(v.id == webhookId) {
            return false
          }
          return true
        })
        setWebhooks(newWebhooks)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setSubmittingDeleteWh(false)
      })
  }

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  return (
    <div className="p-10">
      <div className="flex items-center justify-between">
        <h1 className="text-2x m-0">Admin Action</h1>

        <button className="btn" type="button" onClick={logout}>
          Logout
        </button>
      </div>

      {user && <p className="text-2x">You're logged-in as {user}. Here's a - mandatory actions for the administrator:</p>}

      {!user && (
        <p className="text-2x">
          You're not logged in, you need to <a href="/login">Log in</a>
        </p>
      )}

      <table className="w-full">
        <thead>
          <tr>
            <th>Action Name</th>
            <th>Execute</th>
          </tr>
        </thead>
        <tbody>
          { !submittingPermId && !permIdServiceAcc &&
              <tr>
                <td>
                    <p className="text-2x">
                        Create ServiceAcc Permissions
                    </p>
                </td>
                <td>
                  {submittingPerm ?
                    <button className="btn" >
                        Submitting...
                    </button> :
                    <button className="btn" onClick={ handleCreateServiceAccPermissions }>
                        Create ServiceAcc Permissions
                    </button>                  
                  }
                </td>
              </tr>
          }
          { !submittingPermId && permIdServiceAcc && !permIdAppover &&
              <tr>
                <td>
                    <p className="text-2x">
                        Create Approval Permissions
                    </p>
                </td>
                <td>
                  {submittingPerm ?
                    <button className="btn" >
                        Submitting...
                    </button> :
                    <button className="btn" onClick={ handleCreateApproverPermissions }>
                        Create Approval Permissions
                    </button>                  
                  }
                </td>
              </tr>
          }
          { !submittingPermId && permIdServiceAcc && !permIdWalletOwner &&
              <tr>
                <td>
                    <p className="text-2x">
                        Create Wallet Owner Permissions
                    </p>
                </td>
                <td>
                  {submittingPerm ?
                    <button className="btn" >
                        Submitting...
                    </button> :
                    <button className="btn" onClick={ handleCreateWalletOwnerrPermissions }>
                        Create WalletOwner Perm
                    </button>                  
                  }
                </td>
              </tr>
          }
          { permIdServiceAcc && !serviceAccId && !submittingServiceAccID &&
              <tr>
                <td>
                    <p className="text-2x">
                        Create ServiceAcc
                    </p>
                </td>
                <td>
                  {submittingServiceAcc ?
                    <button className="btn" >
                        Submitting...
                    </button> :
                    <button className="btn" onClick={ handleCreateServiceAcc }>
                        Create ServiceAcc
                    </button>                  
                  }
                </td>
              </tr>
          }
          {/* { serviceAccId && !assignId &&
              <tr>
                <td>
                    <p className="text-2x">
                        Create ServiceAcc
                    </p>
                </td>
                <td>
                  {submittingServiceAcc ?
                    <button className="btn" >
                        Submitting...
                    </button> :
                    <button className="btn" onClick={ handleAssignPermitionsServiceAcc }>
                        Assign Permission ServiceAcc
                    </button>                  
                  }
                </td>
              </tr>
          } */}

          { !webhookId && serviceAccId &&
              <tr >
                <td>
                    <p className="text-2x">
                        Create Webhook: 
                    </p>
                </td>
                <td>
                    {
                        submittingWebhookId ?
                        <button className="btn" >
                            Submitting...
                        </button> :
                        <button className="btn" onClick={ handleCreateWebhook }>
                            Create Webhook
                        </button>
                    }

                </td>
              </tr>
              }

          {//users && serviceAccId &&
           // users.map((usr) => (
              <tr >
                <td>
                    {/* <p className="text-2x">
                        Create User with email: {usr.email}
                    </p> */}
                  <input 
                    className="input" 
                    id="username" 
                    name="username" 
                    placeholder="wallet owner email" 
                    value={username} 
                    onChange={handleUsernameChange}
                    />
                </td>
                <td>
                    {(
                          submittingWalletOwner ?
                          <button className="btn" >
                              Submitting...
                          </button> :
                        <button className="btn" onClick={ handleCreateUser }>
                            Create User
                        </button>
                        )
                    }

                </td>
              </tr>
            }
        </tbody>
      </table>

      <br />

      {error && <div className="text-red-700">{error.message}</div>}
      {respMessage && <div className="text-red-700">{respMessage}</div>}

      <p className="text-3x">
          <span className='font-bold'>Webhooks</span> 
      </p>

      <table className="w-full">
        <thead>
          <tr>
            <th>Url</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {webhooks &&
          webhooks.map((wh) => (
            <tr key={wh.id}>
              <td>
                  <p className="text-2x">
                      {wh.url}
                  </p>
              </td>
              <td>
                <button className="btn" onClick={ ()  => {
                  handleDeleteWebhook(wh.id)
                  }}>
                  Delete
                </button>
              </td>
            </tr>
        ))}
        </tbody>
      </table>

      <br />


      <p className="text-3x">
          <span className='font-bold'>Users</span> 
      </p>

      <table className="w-full">
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users &&
          users.items.map((u) => (
            <tr key={u.userId}>
              <td>
                  <p className="text-2x">
                      {u.username}
                  </p>
              </td>
              <td>
                  <p className="text-2x">
                      {}
                  </p>
              </td>
              <td>
                <button className="btn" onClick={ ()  => {
                  handleArchiveUser(u.userId)
                }}>
                  Delete
                </button>
              </td>
            </tr>
        ))}
        </tbody>
      </table>

      <br />

      {error && <div className="text-red-700">{error.message}</div>}
      {respMessage && <div className="text-red-700">{respMessage}</div>}

    </div>
  )
}
