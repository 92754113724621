import { DfnsError } from '@dfns/sdk/dfnsError'
import jwt_decode from 'jwt-decode'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { authApi, dfnsApi } from '../api'
import { WebAuthnSigner } from '@dfns/sdk-browser'
import { CreateCredentialWithCodeResponse } from '@dfns/sdk/generated/auth'
import { UserRoles } from '../types/apptypes'

export interface AuthContextType {
  loading: boolean
  user?: string
  error?: DfnsError
  login: (username: string, orgId: string) => void
  logout: () => void
  register: (orgId: string, username: string, registrationCode: string) => void
  createCred: (code: string, appId: string, orgId: string) => Promise<CreateCredentialWithCodeResponse | undefined>
  orgId?: string
  userId?: string
  userRole?: string
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

const extractUser = (accessToken: string): string => {
  const decoded: Record<string, string> = jwt_decode(accessToken)
  return decoded['https://custom/username']
}

export const AuthProvider = ({ children }: { children: ReactNode }): React.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [user, setUser] = useState<string | undefined>()
  const [error, setError] = useState<DfnsError | undefined>()
  const [init, setInit] = useState<boolean>(true)
  const navigate = useNavigate()
  const [orgId, setOrgId] = useState<string | undefined>()
  const [userId, setUserId] = useState<string | undefined>()
  const [userRole, setUserRole] = useState<string | undefined>()

  useEffect(() => {
    const authToken = localStorage.getItem('DFNS_AUTH_TOKEN')
    if (authToken) {
      setUser(extractUser(authToken))
    }
    setInit(false)
  }, [])

  useEffect(() => {
    const orgId = localStorage.getItem('DFNS_ORG_ID')
    const userId = localStorage.getItem('USER_ID')
    const role = localStorage.getItem('USER_ROLE')
    if (orgId) {
      setOrgId(orgId)
    }
    if (userId) {
      setUserId(userId)
    }

    if (role) {
      setUserRole(role)
    }
  }, [])

  const login = (username: string, orgId: string) => {
    setLoading(true)
    setError(undefined)

    authApi()
      .login({
        username,
        orgId,
      })
      .then(({ token, appToken, userData }) => {
        localStorage.setItem('DFNS_AUTH_TOKEN', token)
        localStorage.setItem('DFNS_APP_ID', userData.appId)
        localStorage.setItem('X-App-Token', appToken)
        localStorage.setItem('DFNS_ORG_ID', userData.orgId)
        localStorage.setItem('USER_ROLE', userData.userRole.toString())
        localStorage.setItem('USER_ID', userData.userId)

        setUser(extractUser(token))
        setOrgId(userData.orgId)
        setUserId(userData.userId)
        setUserRole(userData.userRole.toString())

        console.log(userData)
        if (userData.userRole == UserRoles.Admin) {
          navigate('/admin/action')
        } else if (userData.userRole == UserRoles.Approver) {
          navigate('/approver/action')
        }
        else {
          navigate('/')
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const logout = () => {
    dfnsApi()
    .auth.logout()
    .catch((err) => setError(err))
    .finally(() => {
      setUser(undefined)
      localStorage.removeItem('DFNS_AUTH_TOKEN')
      localStorage.removeItem('X-App-Token')
      localStorage.removeItem('DFNS_ORG_ID')
      localStorage.removeItem('DFNS_APP_ID')
      localStorage.removeItem('USER_ROLE')
      localStorage.removeItem('USER_ID')
      const role = localStorage.getItem('USER_ROLE')
      console.log("Role logout ", role)
      navigate('/login')
    })
  }

  const register = (orgId: string, username: string, registrationCode: string) => {
    setLoading(true)
    setError(undefined)

    authApi()
      .register({ orgId, username, registrationCode })
      .then(() => {
        navigate('/')
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const createCred = async (code: string, appId: string, orgId: string): Promise<CreateCredentialWithCodeResponse | undefined> => {
    setLoading(true)
    setError(undefined)

    try {
      const challenge = await dfnsApi().auth.createCredentialChallengeWithCode({
        body: { code, appId: appId, credentialKind: 'Fido2' },
      })

      if (challenge.kind !== 'Fido2') {
        throw Error('Not a Fido2 challenge') // this check is meant for proper typescript type inferrence
      }

      const attestation = await new WebAuthnSigner().create(challenge)

      const credential = await dfnsApi().auth.createCredentialWithCode({
          body: {
          credentialName: 'Admin Cred - ' + new Date().toISOString(),
          challengeIdentifier: challenge.challengeIdentifier,
          credentialKind: attestation.credentialKind,
          credentialInfo: attestation.credentialInfo,
          appId: appId,
          orgId: orgId,
          userId: challenge.user.id,
          email: challenge.user.name
        },
      })

      return credential
    } catch (err) {
      setError(err as any)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthContext.Provider value={{ loading, user, error, login, logout, register, createCred, orgId, userId, userRole }}>
      {!init && children}
    </AuthContext.Provider>
  )
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext)
}

