import { DfnsError } from '@dfns/sdk/dfnsError'
import { CreateWalletBody } from '@dfns/sdk/types/wallets'
import React, { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import '../globals.css'
import { dfnsApi } from '../api'


export default function CreateWallet(): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<DfnsError | undefined>(undefined)
  const [approvers, setApprovers] = useState<Map<number, string>>(new Map<number, string>([[0, ""]]))
  const [network, setNetwork] = useState<string>("")
  const navigate = useNavigate()

  const handleCreate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // event.preventDefault()

    // const formData = new FormData(event.currentTarget)
    setSubmitting(true)
    setError(undefined)

    dfnsApi()
      .wallets.createWallet({ body: { 
        network: network as CreateWalletBody['network'],
        approverEmails: [...approvers.values()]
      } })
      .then(() => navigate('/'))
      .catch((err) => {
        setError(err)
        setSubmitting(false)
      })
  }

  const handleDeleteApprover = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx: number) => {
    approvers.delete(idx)
    setApprovers(new Map(approvers));  
  }

  const handleAddApprover = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setApprovers(new Map(approvers.set(approvers.size + 1, "")));  
  }

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    setApprovers(new Map(approvers.set(idx, e.target.value)));
  };

  const handleNetworkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNetwork(e.target.value);
  };

  return (
    // <form >
      <div className="w-full p-10">
        <h1 className="text-2x">Create Wallet</h1>

        <table className="w-full">
          <thead>
            <tr>
              <th>Approver email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {approvers &&
            Array.from(approvers).map((apr) => (
              <tr key={apr[0]}>
                <td>
                  <input 
                    className="input" 
                    id="username" 
                    name="username" 
                    placeholder="email" 
                    value={apr[1]} 
                    onChange={ (e) => handleUsernameChange(e, apr[0])}
                    />
                </td>
                <td>
                  <button className="btn" onClick={ (e) => handleDeleteApprover(e, apr[0]) }>
                    Delete Approver
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>

        <div className="items-center gap-2">

          <input 
            className="input" 
            id="network" 
            name="network" 
            placeholder="network" 
            value={network} 
            onChange={ handleNetworkChange }
            />
        </div>

        <div className="items-center gap-2">
          <button className="btn mt-10 mr-10" disabled={submitting} onClick={ handleAddApprover }>
            Add Approver
          </button>

          { submitting ?
            <button className="btn mt-10 mr-10" >
              Submiting...
            </button>
              :                    
            <button className="btn mt-10 mr-10" disabled={submitting} onClick={ handleCreate }>
              Submit
            </button>
          }
        </div>

        <br />

        {!!error && <div className="text-red-700">{error.message}</div>}
      </div>
    // </form>
  )
}
