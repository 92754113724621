import { ListWalletsResponse } from '@dfns/sdk/types/wallets'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import '../globals.css'
import { dfnsApi } from '../api'
import useAuth from '../hooks/useAuth'
import { DfnsError } from '@dfns/sdk'

export default function Home(): JSX.Element {
  const { user, logout } = useAuth()
  const [wallets, setWallets] = useState<ListWalletsResponse | undefined>(undefined)
  const [error, setError] = useState<DfnsError | undefined>(undefined)
  // const [submittingWalletsList, setSubmittingPerm] = useState<boolean>(false)

  useEffect(() => {
    dfnsApi()
      .wallets.listWallets({ query: { limit: '400' } })
      .then((wallets) => setWallets(wallets))
      .catch((err) => {
        console.log(err)
        setError(err)
      })
      .finally(() => {
        // setSubmittingPerm(false)
      })
  }, [])

  return (
    <div className="p-10">
      <div className="flex items-center justify-between">
        <h1 className="text-2x m-0">Home</h1>

        <button className="btn" type="button" onClick={logout}>
          Logout
        </button>
      </div>

      {user && <p className="text-2x">You're logged-in as {user}. Here's a - limited - list of your wallets:</p>}

      {!user && (
        <p className="text-2x">
          You're not logged in, you need to <a href="/login">Log in</a>
        </p>
      )}

      <table className="w-full">
        <thead>
          <tr>
            <th>ID</th>
            <th>Network</th>
            <th>Date Created</th>
          </tr>
        </thead>
        <tbody>
          {wallets &&
            wallets.items.map((wallet) => (
              <tr key={wallet.id}>
                <td><a href={`/wallet/${wallet.id}`}>{wallet.id}</a></td>
                <td>{wallet.network}</td>
                <td>{wallet.dateCreated}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="flex justify-end">
        <Link className="btn" to="/wallets/new">
          Create a new Wallet
        </Link>
      </div>

      <br/>

      {error && <div className="text-red-700">{error.message}</div>}
    </div>
  )
}
