import React, { useState } from 'react';

interface ModalProps {
  label: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (field1: string) => void;
}

const ModalField: React.FC<ModalProps> = ({label, isOpen, onClose, onSubmit }) => {
  const [field1, setField1] = useState<string>('');

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(field1);
    setField1('');
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[30rem]">
        <h2 className="text-xl mb-4">{label}</h2>
        <div className="mb-4">
          <label htmlFor="field1" className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <input
            id="field1"
            type="text"
            value={field1}
            onChange={(e) => setField1(e.target.value)}
            className="ml-0 input w-full ml-0"
          />
        </div>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="btn"
          >
            Confirm action
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalField;
