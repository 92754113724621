import React, { FormEvent, useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import '../globals.css'
import { UserRoles } from '../types/apptypes'
import useAuth from '../hooks/useAuth'

export default function AccessDenied(): JSX.Element {
  const { user, logout } = useAuth()
  const [roleName, setRoleName] = useState<string>("")

  const location = useLocation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search); 
    const roleQ = params.get('role'); 
    return { roleQ };
  };

  useEffect(() => {
    const { roleQ } = getQueryParams();
    if (roleQ && roleQ == UserRoles.Admin.toString()) {
      setRoleName("Administrator")
    } else if (roleQ && roleQ == UserRoles.WalletOwner.toString()) {
      setRoleName("Wallet Owner")
    } else if (roleQ && roleQ == UserRoles.Approver.toString()) {
      setRoleName("Approver")
    } else {
      setRoleName("Underfined")
    }
  }, [])

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="p-6">
        Access Denied
      </div>
      <div className="p-6">
        Try to <a onClick={logout} className='cursor-pointer'>login</a> as the {roleName}
      </div>
    </div>
  )
}
