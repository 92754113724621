import { WebAuthnSigner } from '@dfns/sdk-browser'
import { DfnsApiClient, DfnsAuthenticator } from '@dfns/sdk'

export const authApi = (): DfnsAuthenticator => {
  const signer = new WebAuthnSigner()
  return new DfnsAuthenticator({
    appId: localStorage.getItem('DFNS_APP_ID') ?? "",
    baseUrl: process.env.REACT_APP_BACKEND_API_URL!,
    signer,
  })
}

export const dfnsApi = (): DfnsApiClient => {
  const signer = new WebAuthnSigner()
  return new DfnsApiClient({
    appId: localStorage.getItem('DFNS_APP_ID') ?? "",
    authToken: localStorage.getItem('DFNS_AUTH_TOKEN') ?? undefined,
    appToken: localStorage.getItem('X-App-Token') ?? undefined,
    baseUrl: process.env.REACT_APP_BACKEND_API_URL!,
    signer,
  })
}
