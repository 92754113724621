import React from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import Login from '../pages/login'
import Credential from '../pages/credential'
import Home from '../pages/home'
import CreateWallet from '../pages/create_wallet'
import RegistrationInit from '../pages/init_registration'
import RegistrationStart from '../pages/init_registration_with_email'
import AdminAction from '../pages/admin_action'
import ApproverAction from '../pages/approver_action'
import Wallet from '../pages/wallet'
import { UserRoles } from '../types/apptypes'
import AccessDenied from '../pages/access_denied'


const AuthenticatedRoute: React.FC<{
  role: UserRoles;
}> = ({ role }) =>  {
  const { user, userRole } = useAuth()
  if (!user) {
    return <Navigate to="/login" replace={true} />
  }

  console.log("Current Role ", userRole, "Role ",role)

  if (userRole && userRole != role.toString()) {
    const path = `/access_denied?role=${role}`
    return <Navigate to={path} replace={true} />
  }
  return <Outlet />
}

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<AuthenticatedRoute role={UserRoles.WalletOwner} />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/wallets/new" element={<AuthenticatedRoute role={UserRoles.WalletOwner} />}>
        <Route path="/wallets/new" element={<CreateWallet />} />
      </Route>
      <Route path="/wallet/:walletId" element={<AuthenticatedRoute role={UserRoles.WalletOwner} />}>
        <Route path="/wallet/:walletId" element={<Wallet />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/credential" element={<Credential />} />
      {/* <Route path="/register/start" element={<RegistrationStart />} /> */}
      <Route path="/register" element={<RegistrationInit />} />
      <Route path="/access_denied" element={<AccessDenied />} />

      <Route path="/admin/action" element={<AuthenticatedRoute role={UserRoles.Admin} />}>
        <Route path="/admin/action" element={<AdminAction />} />
      </Route>
      <Route path="/approver/action" element={<AuthenticatedRoute role={UserRoles.Approver} />}>
        <Route path="/approver/action" element={<ApproverAction />} />
      </Route>


    </Routes>
  )
}
