export enum UserStatuses {
    PendingCode = 1,
    Registered,
    AwaitingApprovalAdd,
    AwaitingApprovalDelete,
}

export enum UserRoles {
    Admin = 1,
    WalletOwner,
    Approver,
}